import {
  FormHeaderLabel,
  InfoContainer,
  InfoText,
  InputFile,
  InputTextLabel,
  Panel,
  PanelHeader,
  RadioButton,
  Select,
  SubmitButton,
  TextInput,
} from '@netspresso/components';
import {
  apiClient,
  API_MODEL_V1,
  API_PACKAGE_V1,
  isEmpty,
  JSONValue,
  objectToFormData,
  Partial,
  PLACEHOLDER_PACKAGE_NAME,
  RUNTIME_SAMPLE_LINK,
  WHEEL_FILE_SAMPLE_CODE,
  NotificationMessages,
} from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../components/Notifications';
import { FILE, MODEL_ID, PACKAGE_FORMAT, PACKAGE_NAME, PYTHON_VERSION } from '../../constants';
import { useLoaderContext, useModalContext, usePackagingContext } from '../../context';
import { FRAMEWORKS, Frameworks, Model } from '../../lib';
import { LoaderActions, ModalActions, packagableReducer } from '../../reducers';
import { PackagingType } from '../../schemes';
import { download, parseJsonWithNaN, useFetch, useGTM } from '../../utils';

export const PackagingModel: React.FC = () => {
  const navigate = useNavigate();
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { modelUid } = useParams();
  const { setPageToDataLayer } = useGTM();

  const { setValue, handleSubmit, watch, control, reset } = usePackagingContext();
  const { isDirty, errors, isValid } = useFormState({ control });
  const watched = watch();

  const [filteredModels, setFilteredModels] = useState<Partial<Model>[]>([]);

  const [packageFormat] = useState('python-wheel');
  const [showCopied, setShowCopied] = useState(false);
  const [packageFile, setPackageFile] = useState<File | null>(null);
  const [originFrom, setOriginFrom] = useState('');
  const [framework, setFramework] = useState<Frameworks>();

  const { data: modelData, loading: modelLoading } = useFetch<JSONValue>(API_MODEL_V1);

  const isDisabled = !isDirty || !isEmpty(errors) || !watched[PACKAGE_NAME] || !isValid;

  useEffect(() => {
    setPageToDataLayer('Packaging Model');

    return () => reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: modelLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [modelLoading, dispatchLoading]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (filteredModels && name === MODEL_ID && value.model_id) {
        setFrameworkAndOriginFromSelectedModel(filteredModels, value.model_id);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, filteredModels]);

  useEffect(() => {
    if (modelData) {
      const packagableModels = parseJsonWithNaN<Model[]>(modelData).reduce(packagableReducer, []);

      if (packagableModels.length === 0) {
        showToast(
          <Toast
            content={NotificationMessages.noOptionToSelectForPackaging}
            level={LEVEL_WARNING}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      setFilteredModels([{ model_id: '', display_name: 'Select model' }, ...packagableModels]);

      if (modelUid) {
        setValue(MODEL_ID, modelUid || '', { shouldDirty: true, shouldValidate: true });
        setFrameworkAndOriginFromSelectedModel(packagableModels, modelUid);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, modelUid, setValue]);

  const setFrameworkAndOriginFromSelectedModel = (models: Partial<Model>[], selectedModelId: string) => {
    const selectedModel = models.find((model) => model.model_id === selectedModelId);

    if (selectedModel) {
      setFramework(selectedModel.framework);
      setOriginFrom(selectedModel.origin_from || 'npms');
    }
  };

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(PACKAGE_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onPythonVersionChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(PYTHON_VERSION, value, { shouldValidate: true, shouldDirty: true });
  };

  const copyCodeToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  };

  const onCopySampleCode: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyCodeToClipboard(WHEEL_FILE_SAMPLE_CODE);

    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  const onSubmit: SubmitHandler<PackagingType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.post(`${API_PACKAGE_V1}`, objectToFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status === 200) {
        const downloadUrl = res.data.message;

        download(downloadUrl);

        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to download wheel file successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/models');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Downloading the wheel file is failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
      dispatchModal({ type: ModalActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<PackagingType> = (packagingErrors) => {
    // eslint-disable-next-line no-console
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setValue(MODEL_ID, value, { shouldValidate: true, shouldDirty: true });
  };

  const onPackageFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setPackageFile(null);
      setValue(FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setPackageFile(file);
    setValue(FILE, file, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Panel>
      <PanelHeader className="pl-6 mb-4">Packaging</PanelHeader>
      <section className="bg-white rounded-lg shadow mb-6 p-6">
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <h2 className="text-secondary font-semibold mb-4">Model info</h2>
          <section className="mb-4">
            <InputTextLabel htmlFor={PACKAGE_NAME}>Package name *</InputTextLabel>
            <div className="flex flex-row">
              <span className="flex w-12 mr-2 text-sm items-center justify-center border border-defaultGray rounded">
                NP-
              </span>
              <TextInput
                id={PACKAGE_NAME}
                placeholder={PLACEHOLDER_PACKAGE_NAME}
                error={errors[PACKAGE_NAME] ? errors[PACKAGE_NAME].message : ''}
                value={watched[PACKAGE_NAME]}
                width="w-full"
                onChange={onNameChange}
              />
            </div>
          </section>
          <InfoContainer className="mb-6">
            <ul className="w-full list-disc flex flex-col ml-3">
              <li className="text-xs text-secondary leading-[18px]">Package name will be the library name.</li>
              <li className="text-xs text-secondary leading-[18px]">
                The library name will not be changed even if you change the downloaded package name.
              </li>
            </ul>
          </InfoContainer>
          <h2 className="text-secondary font-semibold mb-4">Select model</h2>
          <section className="mb-4 flex flex-row items-center">
            <div className="w-1/2">
              <Select
                className="font-semibold mb-2"
                label="Base model *"
                valueProp="model_id"
                labelProp="display_name"
                value={watched[MODEL_ID]}
                options={filteredModels}
                onChange={onSelectChange}
              />
            </div>
            {originFrom &&
              (originFrom === 'npms' ? (
                <span className="rounded-full bg-notaNavy-100 text-notaNavy-800 text-xxs font-medium px-2 py-1 mt-6 ml-4">
                  NetsPresso
                </span>
              ) : (
                <div className="rounded-full bg-gray-300 text-darkGray text-xxs font-medium px-2 py-1 mt-6 ml-4">
                  Custom
                </div>
              ))}
          </section>
          {framework === FRAMEWORKS.TENSORRT && (
            <InfoContainer className="mb-6">
              <InfoText color="secondary">
                Packaged TensorRT models will only work properly on the machine on which converted TensorRT.
              </InfoText>
            </InfoContainer>
          )}
          <h2 className="text-secondary font-semibold mb-4">Packaging options</h2>
          <section className="mb-4">
            <FormHeaderLabel>Format *</FormHeaderLabel>
            <div>
              <RadioButton
                groupName={PACKAGE_FORMAT}
                value="python-wheel"
                label="Python wheel"
                classes="mr-6"
                isChecked={packageFormat === 'python-wheel'}
              />
              <RadioButton
                groupName={PACKAGE_FORMAT}
                value="linux-so"
                label="Linux SO"
                classes="mr-6"
                isChecked={packageFormat === 'linux-so'}
                isDisabled
              />
            </div>
          </section>
          <section className="mb-4">
            <FormHeaderLabel>Python version *</FormHeaderLabel>
            <div>
              <RadioButton
                groupName={PYTHON_VERSION}
                value="py38"
                label="3.8"
                classes="mr-6"
                isChecked={watched[PYTHON_VERSION] === 'py38'}
                onChange={onPythonVersionChange}
              />
              <RadioButton
                groupName={PYTHON_VERSION}
                value="py39"
                label="3.9"
                classes="mr-6"
                isChecked={watched[PYTHON_VERSION] === 'py39'}
                onChange={onPythonVersionChange}
              />
            </div>
          </section>
          <h3 className="text-darkGray text-sm font-semibold mb-1 mt-4">Pre/Post processing code</h3>
          <section className="flex flex-row mb-4 w-full">
            <div className="w-full flex flex-row">
              <span className="w-1/6 font-darkGray leading-8">Pre/Post processing</span>
              <InputFile
                id="packaging-file"
                accept=".py"
                error={errors[FILE] ? errors[FILE].message : ''}
                placeholder="Select .py file"
                localFile={packageFile}
                onChange={onPackageFileChange}
              />
            </div>
          </section>
          <InfoContainer className="mb-4">
            <InfoText color="secondary">
              Please download{' '}
              <a
                href={RUNTIME_SAMPLE_LINK}
                target="_blank"
                rel="noreferrer"
                className="underline font-semibold"
                data-test="validator-link"
              >
                processing code examples
              </a>{' '}
              and modify them for your model to get intended inference results.
            </InfoText>
          </InfoContainer>
          <section className="relative mb-6">
            <span className="block font-semibold text-sm text-darkGray mb-1">Install and run package</span>
            <div className="relative border border-defaultGray rounded p-4">
              <pre className="text-xs font-mono">
                <span className="text-code-resword">from</span> {`np_{package_name}.models.model `}
                <span className="text-code-resword">import</span> NPModel
                <br />
                <br />
                NPModel.initialize(num_threads=
                <span className="text-code-number">1</span>){'  '}
                <span className="text-code-comment"># Initialize</span>
                <br />
                npmodel = NPModel()
                <br />
                image_path = <span className="text-code-string">&#34;./test.jpg&#34;</span>
                {'  '}
                <span className="text-code-comment">#Image path</span>
                <br />
                print(npmodel.run(image_path)){'  '}
                <span className="text-code-comment"># Inference</span>
                <br />
                NPModel.finalize(){'  '}
                <span className="text-code-comment"># Memory management</span>
              </pre>
              <button type="button" className="absolute top-4 right-4" onClick={onCopySampleCode}>
                <span className="material-icons text text-defaultGray">content_copy</span>
              </button>
            </div>
            {showCopied ? (
              <span className="absolute text-sm text-success-active top-0 right-0">
                Sample code is copied in clipboard.
              </span>
            ) : null}
          </section>
          <div className="flex justify-end">
            <SubmitButton disabled={isDisabled}>Start packaging</SubmitButton>
          </div>
        </form>
      </section>
    </Panel>
  );
};
