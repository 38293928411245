import { HeaderText } from '@netspresso/components';
import { NotificationMessages } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LEVEL_DANGER, LEVEL_WARNING, Toast, useNotificationContext } from '../../../../../../components';
import { BASE_MODEL } from '../../../../../../constants';
import { useCompressFormContext, useLoaderContext } from '../../../../../../context';
import { CompressModel } from '../../../../../../lib';
import { LoaderActions } from '../../../../../../reducers';
import { CompressService } from '../../../../../../services';
import { useGTM } from '../../../../../../utils';
import { ModelSetting } from '../../../../components';
import { MethodSelector, SelectBaseModel } from './components';

export const SelectMethod: React.FC = () => {
  const [models, setModels] = useState<CompressModel[]>([]);
  // const [datasetFile, setDatasetFile] = useState<File | null>(null);
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { modelUid } = useParams();

  const {
    compressForm: { getValues, setValue },
  } = useCompressFormContext();
  const { setPageToDataLayer } = useGTM();

  const [, dispatchLoading] = useLoaderContext();

  const getCompressible = (compressModels: CompressModel[]) =>
    compressModels.filter((model) => model.status.is_compressible);

  const fetchModels = async () => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await CompressService.getParentModels();
      const compressibleModels = getCompressible(res.data || []);

      if (compressibleModels.length === 0) {
        showToast(
          <Toast
            content={NotificationMessages.noOptionToSelectForCompress}
            level={LEVEL_WARNING}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      setModels(compressibleModels);

      if (!res.data) {
        return;
      }

      let updateModel = getValues(BASE_MODEL) || res.data[0];

      if (modelUid) {
        updateModel = res.data.find((el) => el.model_id === modelUid) || res.data[0];
      }

      setValue(BASE_MODEL, updateModel, { shouldValidate: true });
    } catch (err) {
      showToast(
        <Toast
          content="Fetching Models failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  useEffect(() => {
    setPageToDataLayer('Select Compression Method');
    fetchModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Compression info
      </HeaderText>
      <ModelSetting />
      <SelectBaseModel models={models} />
      <MethodSelector />
    </>
  );
};
