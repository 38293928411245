import { HeaderText, OutlineButton, SubmitButton } from '@netspresso/components';
import { NotificationMessages, isEmpty } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, LEVEL_WARNING, ModelErrorModal, Toast, useNotificationContext } from '../../../../components';
import { BASE_MODEL, COMPRESSED_MODEL } from '../../../../constants';
import { useAuthContext, useCompressFormContext, useLoaderContext, useModalContext } from '../../../../context';
import { CompressModel } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { CompressModelType } from '../../../../schemes';
import { CompressService } from '../../../../services';
import { useGTM } from '../../../../utils';
import { CompressionResult, ModelSetting } from '../../components';
import { CompressionRatio, SelectBaseModel } from './components';

export const AutomaticCompression: React.FC = () => {
  const [models, setModels] = useState<CompressModel[]>([]);
  const navigate = useNavigate();
  const { user, refetchUserData } = useAuthContext();
  const {
    compressForm: { setValue, handleSubmit, reset, control, getValues },
  } = useCompressFormContext();
  const { isDirty, errors, isValid } = useFormState({ control });
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { modelUid } = useParams();
  const { setPageToDataLayer } = useGTM();

  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();

  const getCompressible = (compressModels: CompressModel[]) =>
    compressModels.filter((model) => model.status.is_compressible);

  const fetchModels = async () => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await CompressService.getParentModels();
      const compressibleModels = getCompressible(res.data || []);

      if (compressibleModels.length === 0) {
        showToast(
          <Toast
            content={NotificationMessages.noOptionToSelectForCompress}
            level={LEVEL_WARNING}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      setModels(compressibleModels);

      if (!res.data) {
        return;
      }

      let updateModel = res.data[0];

      if (modelUid) {
        updateModel = res.data.find((el) => el.model_id === modelUid) || res.data[0];
      }

      setValue(BASE_MODEL, updateModel, { shouldValidate: true });
    } catch (err) {
      const error = err as AxiosError;

      dispatchModal({
        type: ModalActions.Show,
        payload: (
          <ModelErrorModal
            errorTitle="Fetching Models failed"
            errorMessage={error.response?.data.message}
            errorLog={error.response?.data.data.error_log}
            modelId={error.response?.data.data.model_id}
            onSubmit={() => {
              dispatchModal({ type: ModalActions.Hide });
            }}
          />
        ),
      });
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  useEffect(() => {
    setPageToDataLayer('Automatic Compression');
    fetchModels();

    return () => {
      reset();
      dispatchLoading({ type: LoaderActions.Hide });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = () => !isDirty || !isEmpty(errors) || !isValid;

  const handleClickBack = () => {
    navigate(`/compression/new${modelUid ? `/${modelUid}` : ''}`);
  };

  const onCloseModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
    reset();

    navigate('/models');
  };

  const onSubmitResult: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    // modal retrain 핸들러
    event.stopPropagation();
    const compressedModel = getValues(COMPRESSED_MODEL);

    dispatchModal({ type: ModalActions.Hide });
    reset();

    if (compressedModel) {
      navigate(`/projects/retraining/model/${compressedModel.model_id}`);

      return;
    }
    navigate('models');
  };

  const handleConfirmStart = async (data: CompressModelType) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      dispatchModal({ type: ModalActions.Hide });

      const { data: autoCompressResult } = await CompressService.autoCompress(data);
      const { data: compressedModel } = await CompressService.getModel({
        model_id: autoCompressResult.model_id,
      });

      setValue(COMPRESSED_MODEL, compressedModel);
      dispatchLoading({ type: LoaderActions.Hide });
      // 유저 데이터 패치는 비동기적으로 진행되도록
      refetchUserData();
      dispatchModal({
        type: ModalActions.Show,
        payload: <CompressionResult onClose={onCloseModal} onSubmit={onSubmitResult} />,
      });
    } catch (err) {
      const error = err as AxiosError;

      dispatchModal({
        type: ModalActions.Show,
        payload: (
          <ModelErrorModal
            errorTitle="Unable to compress"
            errorMessage={error.response?.data.message}
            errorLog={error.response?.data.data.error_log}
            modelId={error.response?.data.data.model_id}
            onSubmit={() => {
              dispatchModal({ type: ModalActions.Hide });
            }}
          />
        ),
      });
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const handleCancelStart: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onSubmit: SubmitHandler<CompressModelType> = async (data) => {
    if (user.credit >= 25) {
      dispatchModal({
        type: ModalActions.Show,
        payload: (
          <Dialog
            title={NotificationMessages.compressConfirmMessage}
            infoText={NotificationMessages.startProjectMessage(25)}
            onClickConfirm={() => handleConfirmStart(data)}
            onClickCancel={handleCancelStart}
          />
        ),
      });
    } else {
      showToast(
        <Toast
          content={NotificationMessages.notEnoughCredit}
          level={LEVEL_WARNING}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  const onError: SubmitErrorHandler<CompressModelType> = (formErrors) => {
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="w-full px-3">
      <HeaderText className="mb-4 pl-6" type="main">
        Automatic Compression
      </HeaderText>
      <section className="bg-white rounded-lg shadow mb-6 p-6">
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <HeaderText className="mb-4" type="formGroup">
            Compression info
          </HeaderText>
          <ModelSetting />
          <SelectBaseModel models={models} />
          <CompressionRatio />
          <div className="flex justify-end border-t border-defaultGray-100 pt-8">
            <div>
              <OutlineButton className="mr-3" onClick={handleClickBack} type="button">
                Back
              </OutlineButton>
              <SubmitButton disabled={isDisabled()}>Start compression</SubmitButton>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
